import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Header from "./components/Header/Header";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LeadsTable from "./components/LeadsTable/LeadsTable";
import ApiKeyGenerator from "./components/ApiKeyGenerator/ApiKeyGenerator";
import ApiKeysList from "./components/ApiKeysList/ApiKeysList";
import Finance from "./components/Finance/Finance";
import LeadUploader from "./components/LeadUploader/LeadUploader";
import PriceEditor from "./components/PriceEditor/PriceEditor";

const MainContent = () => (
  <>
    <Header />
    <div className="container-fluid" style={{ paddingTop: '80px' }}>
      <div className="row">
        <main role="main" className="">
          <Switch>
            <PrivateRoute exact path="/" component={LeadsTable} />
            <PrivateRoute path="/api-generator" component={ApiKeyGenerator} />
            <PrivateRoute path="/api-keys" component={ApiKeysList} />
            <PrivateRoute path="/finance" component={Finance} />
            <PrivateRoute path="/lead-uploader" component={LeadUploader} />
            <PrivateRoute path="/price-editor" component={PriceEditor} />
            <Redirect to="/" />
          </Switch>
        </main>
      </div>
    </div>
  </>
);

export default MainContent;
