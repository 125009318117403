import React, { useState, useEffect } from "react";
import { Button, Input, Form, Alert, Typography, message } from "antd";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import logo from '../../fox.svg';

const { Title } = Typography;

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const storedAttempts = parseInt(localStorage.getItem("loginAttempts"), 10);
    const lockout = localStorage.getItem("lockout") === "true";
    if (!isNaN(storedAttempts)) {
      setAttempts(storedAttempts);
    }
    if (lockout) {
      setDisabled(true);
      setError("Too many failed attempts. Please try again later.");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("loginAttempts", attempts);
    if (attempts >= 3) {
      localStorage.setItem("lockout", "true");
      setDisabled(true);
      setError("Too many failed attempts. Please try again later.");
    }
  }, [attempts]);

  const handleSubmit = async (values) => {
    if (attempts >= 3) {
      setDisabled(true);
      setError("Too many failed attempts. Please try again later.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const response = await fetch("https://api.fox-fx.live/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const data = await response.json();
        setAttempts((prev) => prev + 1);
        throw new Error(data.message || "Login failed");
      }

      const { token } = await response.json();
      localStorage.setItem("token", token);
      localStorage.removeItem("loginAttempts");
      localStorage.removeItem("lockout");
      window.location.replace("/");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 400, margin: "auto", textAlign: "center" }}>
      <img src={logo} alt="Logo" width="50" style={{ margin: "20px auto" }} />
      <Title level={2}>Login</Title>
      {error && <Alert message={error} type="error" showIcon />}
      <Form onFinish={handleSubmit} style={{ marginTop: 20 }}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            icon={<LoginOutlined />}
            loading={loading}
            disabled={disabled}
            style={{ backgroundColor: 'black', borderColor: 'black' }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
