import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaHome, FaSignOutAlt, FaMoneyBillWave, FaKey, FaFileUpload, FaCommentsDollar } from "react-icons/fa";
import { GiHouseKeys } from "react-icons/gi";
import { Layout, Menu, Button, Dropdown, Grid } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import classnames from "classnames";
import logo from "../../fox.svg";
import './Header.scss'; // Import custom CSS for additional styling

const { Header: AntHeader } = Layout;
const { useBreakpoint } = Grid;

const Header = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const screens = useBreakpoint();
  const location = useLocation();

  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogout = () => {
    // Clear session (remove token from localStorage)
    localStorage.removeItem("token");
    // Redirect user to login page
    window.location.replace("/login");
  };

  const menuItems = (
    <Menu>
      <Menu.Item key="1">
        <NavLink to="/" exact className={classnames({ 'active-link': location.pathname === '/' })}>
          <FaHome style={{ marginRight: 5 }} />
          Leads List
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to="/api-generator" className={classnames({ 'active-link': location.pathname === '/api-generator' })}>
          <FaKey style={{ marginRight: 5 }} />
          API Generator
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to="/api-keys" className={classnames({ 'active-link': location.pathname === '/api-keys' })}>
          <GiHouseKeys style={{ marginRight: 5 }} />
          API Keys
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to="/price-editor" className={classnames({ 'active-link': location.pathname === '/price-editor' })}>
          <FaCommentsDollar style={{ marginRight: 5 }} />
          Price Editor
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink to="/finance" className={classnames({ 'active-link': location.pathname === '/finance' })}>
          <FaMoneyBillWave style={{ marginRight: 5 }} />
          Finance
        </NavLink>
      </Menu.Item>
      <Menu.Item key="6">
        <NavLink to="/lead-uploader" className={classnames({ 'active-link': location.pathname === '/lead-uploader' })}>
          <FaFileUpload style={{ marginRight: 5 }} />
          Lead Upload
        </NavLink>
      </Menu.Item>
      <Menu.Item key="7" onClick={handleLogout}>
        <FaSignOutAlt style={{ marginRight: 5 }} />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader style={{ backgroundColor: 'black' }}>
      <div className="container">
        <NavLink to="/">
          <img src={logo} alt="Logo" width="50" style={{ verticalAlign: 'middle' }} />
        </NavLink>
        {screens.md ? (
          <div className="nav-links" style={{ display: 'flex', alignItems: 'center' }}>
            <NavLink to="/" className={classnames("nav-link", { 'active-link': location.pathname === '/' })} exact>
              <FaHome style={{ marginRight: 5 }} />
              Leads List
            </NavLink>
            <NavLink to="/api-generator" className={classnames("nav-link", { 'active-link': location.pathname === '/api-generator' })}>
              <FaKey style={{ marginRight: 5 }} />
              API Generator
            </NavLink>
            <NavLink to="/api-keys" className={classnames("nav-link", { 'active-link': location.pathname === '/api-keys' })}>
              <GiHouseKeys style={{ marginRight: 5 }} />
              API Keys
            </NavLink>
            <NavLink to="/price-editor" className={classnames("nav-link", { 'active-link': location.pathname === '/price-editor' })}>
              <FaCommentsDollar style={{ marginRight: 5 }} />
              Price Editor
            </NavLink>
            <NavLink to="/finance" className={classnames("nav-link", { 'active-link': location.pathname === '/finance' })}>
              <FaMoneyBillWave style={{ marginRight: 5 }} />
              Finance
            </NavLink>
            <NavLink to="/lead-uploader" className={classnames("nav-link", { 'active-link': location.pathname === '/lead-uploader' })}>
              <FaFileUpload style={{ marginRight: 5 }} />
              Lead Upload
            </NavLink>
            <Button type="link" onClick={handleLogout} style={{ color: 'white' }}>
              <FaSignOutAlt />
              Logout
            </Button>
          </div>
        ) : (
          <Dropdown overlay={menuItems} trigger={['click']} visible={menuVisible} onVisibleChange={handleMenuClick}>
            <Button type="link" icon={<MenuOutlined />} style={{ color: 'white' }} />
          </Dropdown>
        )}
      </div>
    </AntHeader>
  );
};

export default Header;
