import React, { useState } from "react";
import { Typography, Button, Input, Form, Table, Spin, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const LeadUploader = () => {
  const [file, setFile] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handleFileChange = (info) => {
    if (info.file.status === 'done') {
      setFile(info.file.originFileObj);
    }
  };

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleSubmit = async () => {
    setError(null);
    setSuccessMessage("");
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("api_key", apiKey);

      const response = await fetch("https://api.fox-fx.live/leads-xls", {
        method: "POST",
        body: formData,
        headers: {
          "api-key": "1725es3hy4h2vbfq481sq",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to upload leads");
      }

      setLoading(false);
      setSuccessMessage("Leads uploaded successfully");
      message.success("Leads uploaded successfully");
    } catch (error) {
      setLoading(false);
      setError(error.message);
      message.error("Error uploading leads. Please try again.");
    }
  };

  const sampleData = [
    {
      first_name: "Aleksandr",
      last_name: "Testoviy",
      phone: "+380948478458",
      email: "testmailmytest@gmail.com",
    },
    {
      first_name: "Oleg",
      last_name: "Testoviy",
      phone: "+380948478452",
      email: "te2stmailmytest@gmail.com",
    },
    {
      first_name: "Sanya",
      last_name: "Testoviy",
      phone: "+380948478451",
      email: "te3stmailmytest@gmail.com",
    },
    {
      first_name: "Petro",
      last_name: "Testoviy",
      phone: "+380948478455",
      email: "te1stmailmytest@gmail.com",
    },
    {
      first_name: "Dima",
      last_name: "Testoviy",
      phone: "+380948478459",
      email: "test4mailmytest@gmail.com",
    },
  ];

  return (
    <div style={{ maxWidth: 800, margin: "auto", textAlign: "center" }}>
      <Form onFinish={handleSubmit} style={{ marginTop: 40 }}>
        <Form.Item>
          <Upload
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />} style={{ marginBottom: 20, backgroundColor: 'black', borderColor: 'black', color: 'white' }}>
              Upload file
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Input
            type="text"
            value={apiKey}
            onChange={handleApiKeyChange}
            placeholder="API Key"
            style={{ marginBottom: 20 }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!file || !apiKey || loading}
            style={{ backgroundColor: 'black', borderColor: 'black' , color: 'white'}}
          >
            {loading ? <Spin /> : "Upload Leads"}
          </Button>
        </Form.Item>
      </Form>
      {error && <Text type="danger">Error: {error}</Text>}
      {successMessage && <Text type="success">{successMessage}</Text>}
      <Title level={4} style={{ marginTop: 40 }}>Sample Data:</Title>
      <Table dataSource={sampleData} pagination={false} rowKey={(record) => record.email} style={{ marginTop: 20 }}>
        <Table.Column title="Name" dataIndex="first_name" key="first_name" />
        <Table.Column title="Lastname" dataIndex="last_name" key="last_name" />
        <Table.Column title="Phone" dataIndex="phone" key="phone" />
        <Table.Column title="Email" dataIndex="email" key="email" />
      </Table>
    </div>
  );
};

export default LeadUploader;
