import React, { useState } from "react";
import { Button, Input, Typography, Form, message, Spin } from "antd";

const { Title, Text } = Typography;

function ApiKeyGenerator() {
  const [companyName, setCompanyName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  const generateApiKey = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://api.fox-fx.live/apikeys", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": "1725es3hy4h2vbfq481sq", // Add API key to request headers
        },
        body: JSON.stringify({ companyName: companyName }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate API Key");
      }

      const data = await response.json();
      setApiKey(data.apiKey); // Set generated API key to state
      setUserId(data.userId); // Set userId to state
      if (data.companyName !== undefined) {
        setCompanyName(data.companyName); // Set companyName to state, only if it is defined
      }
      message.success("API Key generated successfully");
    } catch (error) {
      console.error("Error generating API Key:", error);
      message.error("Error generating API Key. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
<div style={{ maxWidth: 800, margin: "auto", textAlign: "center" }}>
      <Title level={2} className="mt-5 mb-3 text-center">
        Generate API Key
      </Title>
      <Form onFinish={generateApiKey}>
        <Form.Item
          label="Company Name"
          name="companyName"
          rules={[{ required: true, message: 'Please input your company name!' }]}
        >
          <Input
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
        </Form.Item>
        <Form.Item>
          <Spin spinning={loading}>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: 'black', borderColor: 'black' }}>
              {loading ? "Generating..." : "Generate API Key"}
            </Button>
          </Spin>
        </Form.Item>
      </Form>
      {apiKey && (
        <div className="mt-3">
          <Text strong>Generated API Key: </Text> {apiKey}
          <br />
          <Text strong>User ID: </Text> {userId}
          <br />
          <Text strong>Company Name: </Text> {companyName}
        </div>
      )}
    </div>
  );
}

export default ApiKeyGenerator;
