import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Typography, Spin, message } from 'antd';
import countryData from './countryData.json';

const { Title, Text } = Typography;
const { Option } = Select;

function PriceEditor() {
  const [prices, setPrices] = useState([]);
  const [users, setUsers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [userId, setUserId] = useState('');
  const [country, setCountry] = useState('');
  const [leadInPrice, setLeadInPrice] = useState('');
  const [leadOutPrice, setLeadOutPrice] = useState('');
  const [leadInPriceB2b, setLeadInPriceB2b] = useState('');
  const [leadOutPriceB2b, setLeadOutPriceB2b] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageText, setMessageText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const apiKey = "1725es3hy4h2vbfq481sq";
        const apiKeys = process.env.REACT_APP_APIKEY_ALL;
        const response = await fetch('https://api.fox-fx.live/prices', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "api-key": apiKey,
            "api-keys": apiKeys,
          },
        });
        const data = await response.json();
        setPrices(data);
        const uniqueUserIds = [...new Set(data.map(item => item.user_id))];
        const companies = uniqueUserIds.reduce((acc, userId) => {
          const userData = data.find(item => item.user_id === userId);
          acc[userId] = userData.company_name;
          return acc;
        }, {});
        setUsers(companies);
        const uniqueCountries = [...new Set(data.map(item => item.country))];
        setCountries(uniqueCountries);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching prices:', error);
        setMessageText('Error fetching prices');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleUserChange = (value) => {
    setUserId(value);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const updatePrices = async () => {
    setLoading(true);
    try {
      const apiKey = "1725es3hy4h2vbfq481sq";
      const apiKeys = process.env.REACT_APP_APIKEY_ALL;
      const response = await fetch('https://api.fox-fx.live/update-prices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "api-key": apiKey,
          "api-keys": apiKeys,
        },
        body: JSON.stringify({
          userId: userId,
          country: country,
          leadInPrice: leadInPrice,
          leadOutPrice: leadOutPrice,
        }),
      });
      const data = await response.json();
      setMessageText(data.message);
      message.success(data.message);
      setLoading(false);
    } catch (error) {
      setMessageText('Error updating prices');
      message.error('Error updating prices');
      console.error('Error updating prices:', error);
      setLoading(false);
    }
  };

  const updateB2bPrices = async () => {
    setLoading(true);
    try {
      const apiKey = "1725es3hy4h2vbfq481sq";
      const apiKeys = process.env.REACT_APP_APIKEY_ALL;
      const response = await fetch('https://api.fox-fx.live/update-prices-b2b', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "api-key": apiKey,
          "api-keys": apiKeys,
        },
        body: JSON.stringify({
          userId: userId,
          country: country,
          leadInPriceB2b: leadInPriceB2b,
          leadOutPriceB2b: leadOutPriceB2b,
        }),
      });
      const data = await response.json();
      setMessageText(data.message);
      message.success(data.message);
      setLoading(false);
    } catch (error) {
      setMessageText('Error updating prices');
      message.error('Error updating prices');
      console.error('Error updating prices:', error);
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 800, margin: "auto", textAlign: "center" }}>
      <div style={{  margin: "40px auto" , marginTop: 40}}>
        <Title level={2}>Price Updater</Title>
        <Form layout="vertical">
          <Form.Item label="User ID">
            <Select value={userId} onChange={handleUserChange} placeholder="Select User ID">
              {Object.keys(users).map(userId => (
                <Option key={userId} value={userId}>{users[userId]}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Country">
            <Select value={country} onChange={handleCountryChange} placeholder="Select Country">
              {Object.keys(countryData).map(countryCode => (
                <Option key={countryCode} value={countryCode}>{countryData[countryCode]}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Lead In Price">
            <Input value={leadInPrice} onChange={(e) => setLeadInPrice(e.target.value)} />
          </Form.Item>
          <Form.Item label="Lead Out Price">
            <Input value={leadOutPrice} onChange={(e) => setLeadOutPrice(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={updatePrices}
              loading={loading}
              style={{ backgroundColor: 'black', borderColor: 'black' }}
            >
              Update Prices
            </Button>
          </Form.Item>
        </Form>
        {messageText && <Text>{messageText}</Text>}
      </div>

      <div>
        <Title level={2}>Price B2B Updater</Title>
        <Form layout="vertical">
          <Form.Item label="User ID">
            <Select value={userId} onChange={handleUserChange} placeholder="Select User ID">
              {Object.keys(users).map(userId => (
                <Option key={userId} value={userId}>{users[userId]}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Country">
            <Select value={country} onChange={handleCountryChange} placeholder="Select Country">
              {Object.keys(countryData).map(countryCode => (
                <Option key={countryCode} value={countryCode}>{countryData[countryCode]}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Lead In B2b Price">
            <Input value={leadInPriceB2b} onChange={(e) => setLeadInPriceB2b(e.target.value)} />
          </Form.Item>
          <Form.Item label="Lead Out B2b Price">
            <Input value={leadOutPriceB2b} onChange={(e) => setLeadOutPriceB2b(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={updateB2bPrices}
              loading={loading}
              style={{ backgroundColor: 'black', borderColor: 'black' }}
            >
              Update Prices
            </Button>
          </Form.Item>
        </Form>
        {messageText && <Text>{messageText}</Text>}
      </div>
    </div>
  );
}

export default PriceEditor;
