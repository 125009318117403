import React, { useEffect, useState } from "react";
import { Typography, Table, Pagination, Spin } from "antd";

const { Title } = Typography;

const Finance = () => {
    const [leads, setLeads] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const leadsPerPage = 10;

    useEffect(() => {
        fetchLeads();
    }, [currentPage]);

    const fetchLeads = () => {
        setLoading(true);
        const apiKey = "1725es3hy4h2vbfq481sq";
        const apiKeys = process.env.REACT_APP_APIKEY_ALL;
        const queryParams = new URLSearchParams({
            page: currentPage,
            leadsPerPage: leadsPerPage,
        });

        fetch(`https://api.fox-fx.live/all-leads?${queryParams}`, {
            headers: {
                "api-key": apiKey,
                "api-keys": apiKeys,
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then((leadsData) => {
            // Group leads by company_name_api and count the filtered leads
            const groupedLeads = leadsData.reduce((accumulator, current) => {
                const existingLead = accumulator.find(lead => lead.company_name_api === current.company_name_api);
                if (existingLead) {
                    if (current.payed_for_client === "Yes") {
                        existingLead.leads++;
                    }
                    if (current.payed_for_provider === "Yes") {
                        existingLead.office++;
                    }
                } else {
                    accumulator.push({
                        ...current,
                        leads: current.payed_for_client === "Yes" ? 1 : 0,
                        office: current.payed_for_provider === "Yes" ? 1 : 0,
                    });
                }
                return accumulator;
            }, []);
            setLeads(groupedLeads);
            setLoading(false);
        })
        .catch((error) => {
            console.error("There was a problem with the fetch operation:", error);
            setLoading(false);
        });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const calculateKoef = (row) => {
        return (parseFloat(row.lead_out_price) / parseFloat(row.lead_in_price)).toFixed(2);
    };

    const calculateIncome = (row) => {
        return ((parseFloat(row.lead_out_price) - parseFloat(row.lead_in_price)) * row.leads).toFixed(2);
    };

    const calculateLeadIn = (row) => {
        return (parseFloat(row.lead_in_price) * parseFloat(row.leads)).toFixed(2);
    }

    const calculateLeadOut = (row) => {
        return (parseFloat(row.lead_out_price) * parseFloat(row.leads)).toFixed(2);
    }

    return (
<div style={{ maxWidth: 800, margin: "auto", textAlign: "center" }}>
            <Title level={2} className="mt-5 mb-3 text-center">Finance</Title>
            <Spin spinning={loading}>
                <Table dataSource={leads} rowKey="company_name_api" pagination={false}>
                    <Table.Column title="Client" dataIndex="company_name_api" key="company_name_api" />
                    <Table.Column title="Leads" dataIndex="leads" key="leads" />
                    <Table.Column title="Office" dataIndex="office" key="office" />
                    <Table.Column title="My" key="my" render={(text, record) => calculateLeadIn(record)} />
                    <Table.Column title="Koef" key="koef" render={(text, record) => calculateKoef(record)} />
                    <Table.Column title="Income" key="income" render={(text, record) => calculateIncome(record)} />
                </Table>
            </Spin>
            <Pagination
                total={leads.length}
                pageSize={leadsPerPage}
                current={currentPage}
                onChange={handlePageChange}
                style={{ marginTop: '16px', textAlign: 'center' }}
            />
        </div>
    );
};

export default Finance;
