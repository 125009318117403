import React, { useState, useEffect } from 'react';
import { Typography, Table, Input, Button, Spin, message } from 'antd';

const { Title } = Typography;

function ApiKeysList() {
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(null); // To track which API key is being saved
  const apiKey = '1725es3hy4h2vbfq481sq';

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = () => {
    setLoading(true);
    fetch('https://api.fox-fx.live/apikeys', {
      headers: {
        'api-key': apiKey
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch API keys');
        }
        return response.json();
      })
      .then(data => {
        setApiKeys(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching API keys:', error);
        setLoading(false);
        message.error('Error fetching API keys. Please try again.');
      });
  };

  const handleEdit = (apiKeyId, priority, companyName, leadLimit) => {
    setSaving(apiKeyId);
    fetch(`https://api.fox-fx.live/apikeys/${apiKeyId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'api-key': apiKey
      },
      body: JSON.stringify({ priority, companyName, leadLimit })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to update API key');
        }
        // Update local state after successful update on server
        updateApiKeyInList(apiKeyId, { priority, companyName, leadLimit });
        console.log('API key updated successfully');
        message.success('API key updated successfully');
        setSaving(null);
      })
      .catch(error => {
        console.error('Error updating API key:', error);
        message.error('Error updating API key. Please try again.');
        setSaving(null);
      });
  };

  const updateApiKeyInList = (apiKeyId, updatedFields) => {
    setApiKeys(prevApiKeys =>
      prevApiKeys.map(apiKey =>
        apiKey.api_key === apiKeyId ? { ...apiKey, ...updatedFields } : apiKey
      )
    );
  };

  return (
<div style={{ maxWidth: 800, margin: "auto", textAlign: "center" }}>
      <Title level={2} className="mt-5 mb-3 text-center">API Keys List</Title>
      <Spin spinning={loading}>
        <Table dataSource={apiKeys} rowKey="api_key" pagination={false}>
          <Table.Column title="API Key" dataIndex="api_key" key="api_key" />
          <Table.Column 
            title="Company Name" 
            dataIndex="company_name" 
            key="company_name"
            render={(text, record) => (
              <Input
                value={text}
                onChange={e => updateApiKeyInList(record.api_key, { company_name: e.target.value })}
              />
            )}
          />
          <Table.Column 
            title="Priority" 
            dataIndex="priority" 
            key="priority"
            render={(text, record) => (
              <Input
                type="number"
                value={text}
                onChange={e => updateApiKeyInList(record.api_key, { priority: e.target.value })}
              />
            )}
          />
          <Table.Column 
            title="Lead Limit" 
            dataIndex="lead_limit" 
            key="lead_limit"
            render={(text, record) => (
              <Input
                type="number"
                value={text}
                onChange={e => updateApiKeyInList(record.api_key, { lead_limit: e.target.value })}
              />
            )}
          />
          <Table.Column 
            title="Actions" 
            key="actions"
            render={(text, record) => (
              <Button 
                type="primary" 
                onClick={() => handleEdit(record.api_key, record.priority, record.company_name, record.lead_limit)} 
                style={{ backgroundColor: 'black', borderColor: 'black' }}
                loading={saving === record.api_key}
              >
                Save
              </Button>
            )}
          />
        </Table>
      </Spin>
    </div>
  );
}

export default ApiKeysList;
