// App.jsx
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LoginPage from "./components/LoginPage/LoginPage";
import MainContent from "./MainContent";

import './App.scss'

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={LoginPage} />
                <PrivateRoute path="/" component={MainContent} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
