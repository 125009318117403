import React, { useEffect, useState } from "react";
import { Table, Button, Select, DatePicker, Input, Spin, notification } from "antd";
import { format } from "date-fns";
import './LeadsTable.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const LeadsTable = () => {
	const [leads, setLeads] = useState([]);
	const [filteredLeads, setFilteredLeads] = useState([]);
	const [totalLeads, setTotalLeads] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const leadsPerPage = 30;
	const [creationDateFilter, setCreationDateFilter] = useState([]);
	const [reopeningDateFilter, setReopeningDateFilter] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingTodayBtn, setLoadingTodayBtn] = useState(false);
	const [updateMessage, setUpdateMessage] = useState("");
	const [columnVisibility, setColumnVisibility] = useState({
		id: true,
		first_name: true,
		last_name: true,
		phone: true,
		country: true,
		email: true,
		source: true,
		company_name_api: true,
		company_name_origin: true,
		funnel_name: true,
		lead_in_price: true,
		lead_in_b2b_price: true,
		payed_for_provider: true,
		lead_out_price: true,
		payed_for_client: true,
		call_status: true,
		deposit: true,
		language: true,
		client_type: true,
		creation_date: true,
		reopening_date: true,
	});

	useEffect(() => {
		fetchLeads();
	}, [currentPage]);

	useEffect(() => {
		handleFilter();
	}, [creationDateFilter, reopeningDateFilter]);

	const fetchLeads = () => {
		const apiKey = "1725es3hy4h2vbfq481sq";
		const apiKeys = process.env.REACT_APP_APIKEY_ALL;
		const queryParams = new URLSearchParams({
			page: currentPage,
			leadsPerPage: leadsPerPage,
		});

		fetch(`https://api.fox-fx.live/all-leads?${queryParams}`, {
			headers: {
				"api-key": apiKey,
				"api-keys": apiKeys,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.json();
			})
			.then((leadsData) => {
				// Sort the data in descending order by id or a date field
				const sortedLeads = leadsData.sort((a, b) => b.id - a.id);
				setLeads(sortedLeads);
				setFilteredLeads(sortedLeads);
				setTotalLeads(sortedLeads.length);
			})
			.catch((error) => {
				console.error("There was a problem with the fetch operation:", error);
			});
	};

	const handleUpdateStatus = () => {
		const apiKey = "1725es3hy4h2vbfq481sq";
		const apiKeys = process.env.REACT_APP_APIKEY_ALL;

		setLoading(true);

		setUpdateMessage("");

		fetch("https://api.fox-fx.live/update-call-status", {
			headers: {
				"api-key": apiKey,
				"api-keys": apiKeys,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.text();
			})
			.then((data) => {
				setUpdateMessage("Call statuses updated successfully");
				notification.success({
					message: "Success",
					description: "Call statuses updated successfully",
				});
				fetchLeads();
			})
			.catch((error) => {
				setUpdateMessage(`Error: ${error}`);
				notification.error({
					message: "Error",
					description: `There was an error updating call statuses: ${error}`,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleUpdateTodaysStatus = () => {
		const apiKey = "1725es3hy4h2vbfq481sq";
		const apiKeys = process.env.REACT_APP_APIKEY_ALL;

		setLoadingTodayBtn(true);
		setUpdateMessage("");

		fetch("https://api.fox-fx.live/update-todays-call-status", {
			headers: {
				"api-key": apiKey,
				"api-keys": apiKeys,
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				return response.text();
			})
			.then((data) => {
				setUpdateMessage("Today's call statuses updated successfully");
				notification.success({
					message: "Success",
					description: "Today's call statuses updated successfully",
				});
				fetchLeads();
			})
			.catch((error) => {
				setUpdateMessage(`Error: ${error}`);
				notification.error({
					message: "Error",
					description: `There was an error updating today's call statuses: ${error}`,
				});
			})
			.finally(() => {
				setLoadingTodayBtn(false);
			});
	};

	const handleDepositChange = (newValue, leadId) => {
		const apiKey = "1725es3hy4h2vbfq481sq";
		const apiKeys = process.env.REACT_APP_APIKEY_ALL;

		fetch("https://api.fox-fx.live/update-deposit-status", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"api-key": apiKey,
				"api-keys": apiKeys,
			},
			body: JSON.stringify({ id: leadId, deposit: newValue }),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Deposit status updated successfully");
				fetchLeads();
			})
			.catch((error) => {
				console.error("Error updating deposit status:", error);
			});
	};

	const handleProviderChange = (newValue, leadId) => {
		const apiKey = "1725es3hy4h2vbfq481sq";
		const apiKeys = process.env.REACT_APP_APIKEY_ALL;

		fetch("https://api.fox-fx.live/update-provider-status", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"api-key": apiKey,
				"api-keys": apiKeys,
			},
			body: JSON.stringify({ id: leadId, payed_for_provider: newValue }),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("payed_for_provider updated successfully");
				fetchLeads();
			})
			.catch((error) => {
				console.error("Error updating payed_for_provider status:", error);
			});
	};

	const handleClientChange = (newValue, leadId) => {
		const apiKey = "1725es3hy4h2vbfq481sq";
		const apiKeys = process.env.REACT_APP_APIKEY_ALL;

		fetch("https://api.fox-fx.live/update-client-status", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"api-key": apiKey,
				"api-keys": apiKeys,
			},
			body: JSON.stringify({ id: leadId, payed_for_client: newValue }),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("Payed for Client status updated successfully");
				fetchLeads();
			})
			.catch((error) => {
				console.error("Error updating Payed for Client status:", error);
			});
	};

	const handleVisibilityChange = (selectedColumns) => {
		const newColumnVisibility = {};
		options.forEach((option) => {
			newColumnVisibility[option.value] = selectedColumns.includes(option.value);
		});
		setColumnVisibility(newColumnVisibility);
	};

	const handleFilter = () => {
		let filteredData = leads;

		if (creationDateFilter.length === 2) {
			filteredData = filteredData.filter((lead) => {
				const creationDate = new Date(lead.creation_date);
				return creationDate >= creationDateFilter[0] && creationDate <= creationDateFilter[1];
			});
		}

		if (reopeningDateFilter.length === 2) {
			filteredData = filteredData.filter((lead) => {
				const reopeningDate = new Date(lead.reopening_date);
				return reopeningDate >= reopeningDateFilter[0] && reopeningDate <= reopeningDateFilter[1];
			});
		}

		setFilteredLeads(filteredData);
		setTotalLeads(filteredData.length);
	};

	const visibleColumns = Object.keys(columnVisibility).filter(
		(column) => columnVisibility[column]
	);

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			sorter: (a, b) => b.id - a.id,
			hidden: !columnVisibility.id,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="ID"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.id.toString().includes(value),
		},
		{
			title: "Name",
			dataIndex: "first_name",
			hidden: !columnVisibility.first_name,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Name"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.first_name.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Lastname",
			dataIndex: "last_name",
			hidden: !columnVisibility.last_name,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Lastname"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.last_name.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Phone",
			dataIndex: "phone",
			hidden: !columnVisibility.phone,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Phone"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.phone.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Country",
			dataIndex: "country",
			hidden: !columnVisibility.country,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Country"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.country.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Email",
			dataIndex: "email",
			hidden: !columnVisibility.email,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Email"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Source",
			dataIndex: "source",
			hidden: !columnVisibility.source,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Source"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.source.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Client",
			dataIndex: "company_name_api",
			hidden: !columnVisibility.company_name_api,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Client"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.company_name_api.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Provider",
			dataIndex: "company_name_origin",
			hidden: !columnVisibility.company_name_origin,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Provider"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.company_name_origin.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Funnel Name",
			dataIndex: "funnel_name",
			hidden: !columnVisibility.funnel_name,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Funnel Name"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.funnel_name.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Lead In Price",
			dataIndex: "lead_in_price",
			hidden: !columnVisibility.lead_in_price,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Lead In Price"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.lead_in_price.toString().includes(value),
		},
		{
			title: "B2B Lead In Price",
			dataIndex: "lead_in_b2b_price",
			hidden: !columnVisibility.lead_in_b2b_price,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="B2B In Price"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.lead_in_b2b_price.toString().includes(value),
		},
		{
			title: "Payed for Provider?",
			dataIndex: "payed_for_provider",
			hidden: !columnVisibility.payed_for_provider,
			render: (text, record) => (
				<Select
					defaultValue={text}
					style={{ width: 120 }}
					onChange={(value) => handleProviderChange(value, record.id)}
				>
					<Option value="Yes">Yes</Option>
					<Option value="No">No</Option>
				</Select>
			),
			filters: [
				{ text: 'Yes', value: 'Yes' },
				{ text: 'No', value: 'No' },
			],
			onFilter: (value, record) => record.payed_for_provider.includes(value),
		},
		{
			title: "Lead Out Price",
			dataIndex: "lead_out_price",
			hidden: !columnVisibility.lead_out_price,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Lead Out Price"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.lead_out_price.toString().includes(value),
		},
		{
			title: "Payed for Client?",
			dataIndex: "payed_for_client",
			hidden: !columnVisibility.payed_for_client,
			render: (text, record) => (
				<Select
					defaultValue={text}
					style={{ width: 120 }}
					onChange={(value) => handleClientChange(value, record.id)}
				>
					<Option value="Yes">Yes</Option>
					<Option value="No">No</Option>
				</Select>
			),
			filters: [
				{ text: 'Yes', value: 'Yes' },
				{ text: 'No', value: 'No' },
			],
			onFilter: (value, record) => record.payed_for_client.includes(value),
		},
		{
			title: "Call Status",
			dataIndex: "call_status",
			hidden: !columnVisibility.call_status,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Call Status"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.call_status.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Deposit",
			dataIndex: "deposit",
			hidden: !columnVisibility.deposit,
			render: (text, record) => (
				<Select
					defaultValue={text}
					style={{ width: 120 }}
					onChange={(value) => handleDepositChange(value, record.id)}
				>
					<Option value="1">1</Option>
					<Option value="0">0</Option>
				</Select>
			),
			filters: [
				{ text: '1', value: '1' },
				{ text: '0', value: '0' },
			],
			onFilter: (value, record) => record.deposit.toString() === value,
		},
		{
			title: "Language",
			dataIndex: "language",
			hidden: !columnVisibility.language,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Language"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.language.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Client Type",
			dataIndex: "client_type",
			hidden: !columnVisibility.client_type,
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Client Type"
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => applyFilter()}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => record.client_type.toLowerCase().includes(value.toLowerCase()),
		},
		{
			title: "Creation Date",
			dataIndex: "creation_date",
			render: (text) => format(new Date(text), "dd/MM/yyyy HH:mm"),
			filterDropdown: ({ confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<RangePicker
						style={{ width: 188, marginBottom: 8, display: "block" }}
						onChange={(dates) => {
							setCreationDateFilter(dates);
						}}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => {
				const date = new Date(record.creation_date);
				return date >= creationDateFilter[0] && date <= creationDateFilter[1];
			},
			hidden: !columnVisibility.creation_date,
		},
		{
			title: "Reopening Date",
			dataIndex: "reopening_date",
			render: (text) => format(new Date(text), "dd/MM/yyyy HH:mm"),
			filterDropdown: ({ confirm: applyFilter }) => (
				<div style={{ padding: 8 }}>
					<RangePicker
						style={{ width: 188, marginBottom: 8, display: "block" }}
						onChange={(dates) => {
							setReopeningDateFilter(dates);
						}}
					/>
					<Button
						type="primary"
						onClick={() => applyFilter()}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
				</div>
			),
			onFilter: (value, record) => {
				const date = new Date(record.reopening_date);
				return date >= reopeningDateFilter[0] && date <= reopeningDateFilter[1];
			},
			hidden: !columnVisibility.reopening_date,
		},
	].filter((column) => !column.hidden);

	const options = Object.keys(columnVisibility).map((columnName) => ({
		value: columnName,
		label: columnName,
	}));

	return (
		<div style={{ marginTop : "40px"}}>
			
			<div
				style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
			>
				<Spin spinning={loading}>
					<Button
						onClick={handleUpdateStatus}
						type="primary"
						style={{ marginRight: "20px", backgroundColor: "black", borderColor: "black" }}
					>
						{loading ? "Updating..." : "Update All Statuses"}
					</Button>
				</Spin>
				<Spin spinning={loadingTodayBtn}>
					<Button
						onClick={handleUpdateTodaysStatus}
						type="primary"
						style={{ marginRight: "20px", backgroundColor: "black", borderColor: "black" }}
					>
						{loadingTodayBtn ? "Updating..." : "Update Today's Statuses"}
					</Button>
				</Spin>
			</div>
			<p>
				Total displayed in table: <b>{totalLeads}</b>
			</p>
			<div style={{ marginBottom: "20px" }}>
				<Select
					options={options}
					mode="multiple"
					onChange={handleVisibilityChange}
					value={Object.keys(columnVisibility).filter((key) => columnVisibility[key])}
					style={{ width: '100%' }}
				/>
			</div>
			<div className="custom-table">
				<Table
					rowKey="id"
					dataSource={filteredLeads}
					columns={columns}
					pagination={{
						current: currentPage,
						pageSize: leadsPerPage,
						total: totalLeads,
						onChange: (page) => setCurrentPage(page),
					}}
					loading={loading || loadingTodayBtn}
					defaultSortOrder="descend"
				/>
			</div>
		</div>
	);
};

export default LeadsTable;
